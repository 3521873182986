import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'vant/lib/index.css';
import { NavBar, Button, Field  } from 'vant';

const app = createApp(App);

app.use(NavBar);
app.use(Button);
app.use(Field);
app.use();

app.use(router);

app.mount('#app')
