import axios from 'axios';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export function getAuthUrl() {
    return axios.get(`${API_BASE_URL}/wechat/getAuthUrl`);
}

export function getOpenid(code) {
    return axios.get(`${API_BASE_URL}/wechat/getOpenid?code=`+code)
}

// // auth.js
// import { getAuthUrl } from './api';

// export default function auth(to, from, next) {
//   // 判断用户是否已登录
//   let openid = localStorage.getItem('openid');

//   if (!openid) {
//     getAuthUrl()
//       .then(response => {
//         console.log(response.data);
//         if (response.data) {
//           console.log('res', response.data);
//           let auth_url = response.data.auth_url;
//           window.location.href = auth_url;
//         }
//       })
//       .catch(error => {
//         console.error('Failed to fetch auth url:', error);
//       });
//   }

//   next();
// }