
import { createRouter, createWebHistory } from 'vue-router'
import auth from '../middleware/auth';

const routes = [
    {
        path: '/',
        redirect: '/chat'
    },
    {
        path: '/wechatAuth',
        name: 'wechatAuth',
        component: () => import('../components/WechatAuth.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('../components/LogOut.vue')
    },
    {
        path: '/chat',
        name: 'chat',
        component: () => import('../components/ChatAi.vue'),
        meta: {
            middleware: [auth] // 使用 auth 中间件进行登录验证
        }
    }
];

const router = createRouter({
    history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
    routes: routes
})


router.beforeEach((to, from, next) => {
    // 调用中间件
    if (to.meta.middleware) {
      const middleware = to.meta.middleware;
  
      // 逐个调用中间件函数
      middleware.forEach((m) => {
        m(to, from, next);
      });
    }
  
    next();
});
 
export default router
