<template>
    <div>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    name: 'ChatComponent',

    setup() {
      const messages = ref([]);
  
      const startChat = async () => {
        const startTime = Date.now();
        messages.value = [];
  
        const handleChunk = (chunk) => {
          const chunkTime = (Date.now() - startTime) / 1000;
          const message = chunk.choices[0].message.content;
          messages.value.push({ role: 'assistant', content: message });
          console.log(`Message received ${chunkTime.toFixed(2)} seconds after request: ${message}`);
        };
  
        const api_key = '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';
        const model = 'gpt-3.5-turbo';
        const userMessage = '你好';
  
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${api_key}`,
          },
          body: JSON.stringify({
            model,
            messages: [{ role: 'user', content: userMessage }],
            temperature: 0,
            stream: true
          }),
        });
  
        const data = await response.json();
        data.choices.forEach(handleChunk);
      };
  
      return {
        messages,
        startChat,
      };
    },
  };
  </script>
  